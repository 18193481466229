export enum IssueType {
  feature = 'Feature Request',
  issue = 'Bug'
}

export type IssueRequestCreate = {
  type: IssueType
  userName: string
  email: string
  summary: string
  description: string
  product: string
  attachments?: string[]
}

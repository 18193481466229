import { VFC } from 'react'

import ModalContainer, { Props as ModalProps } from '../ModalContainer/ModalContainer'
import styles from './MessageModal.module.scss'

export type ModalContent = {
  title: string
  message: JSX.Element | string
}

type Props = ModalProps & {
  modalContent?: ModalContent
}

const MessageModal: VFC<Props> = ({ modalContent, open, setOpen }: Props)=> {
  return (
    <ModalContainer open={open} setOpen={setOpen}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={`art-font-heading-xsm ${styles.title}`}>{modalContent?.title}</div>
          <div className={styles.message}>{modalContent?.message}</div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default MessageModal
